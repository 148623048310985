import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import logo from "../../../assets/images/appImages/logo.svg";
import icon1 from "../../../assets/images/icons/value.svg";
import icon2 from "../../../assets/images/icons/mission.svg";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineGoogle,
  AiOutlineTwitter,
} from "react-icons/ai";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">JAMINA</h3>
                <div className="custom-content">
                  JAMINA est une entreprise qui propose une large gamme
                  d'accessoires et de bijoux de qualité à des prix abordables.
                  Que vous soyez à la recherche d'un clutch élégant pour une
                  soirée ou d'un collier délicat pour tous les jours, nous avons
                  ce qu'il vous faut.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous?" />
            <h3 className="custom-title">Valeur, mission</h3>
            <div className="row pt-4">
              <div className="col-md-4 col-lg-4 mb-3 col-wrapper">
                <div className="value-card">
                  <span className="photo-wrapper marrow_testure">
                    <img src={icon1} alt="icon" />
                  </span>
                  <span className="name">Nos valeurs</span>
                  <span className="description">
                    <strong>Abordabilité: </strong>Nous proposons des prix
                    abordables pour que tout le monde puisse profiter de nos{" "}
                    <br />
                    <strong>Service client: </strong>Nous sommes dévoués à
                    fournir un excellent service client.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 mb-3 col-wrapper">
                <div className="value-card">
                  <span className="photo-wrapper marrow_testure">
                    <img src={icon2} alt="icon" />
                  </span>
                  <span className="name">Notre mission</span>
                  <span className="description">
                    Notre mission est de rendre l'élégance accessible à tous.
                    Nous sommes là pour vous aider à trouver les accessoires et
                    les bijoux parfaits pour vous mettre en valeur.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
